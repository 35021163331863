.meta-navigation {
    &--direct-links {
        body:not([data-editmode='true']) & {
            &:not(:has(.meta-navigation__item)) {
                display: none !important;
            }
        }

        &.meta-navigation--on-banner {
            @include media-breakpoint-up(lg) {
                position: absolute;
                z-index: 2;
                right: $grid-gutter-width * .5;
                bottom: 0;
                padding-right: $grid-gutter-width * .5;
                padding-left: $grid-gutter-width * .5;
                width: 33.333333%;
                transform: translate(#{$grid-gutter-width * .5}, calc(50% - 40px));
            }

            @each $breakpoint, $container-padding-size in $container-padding-sizes {
                @include media-breakpoint-up(#{$breakpoint}) {
                    right: $container-padding-size;
                }
            }

            @media (min-width: #{map-get-last-value($container-max-widths)}) {
                right: calc(((100vw - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
                right: calc((((var(--vw, 1vw) * 100) - #{map-get-last-value($container-max-widths)}) * .5) + #{map-get-last-value($container-padding-sizes)});
                width: calc((#{map-get-last-value($container-max-widths)} - #{map-get-last-value($container-padding-sizes) * 2} + #{$grid-gutter-width}) / 3);
            }

            .banner--slider[data-items='1'] + & {
                @include media-breakpoint-up(lg) {
                    transform: translate(#{$grid-gutter-width * .5}, 50%);
                }
            }

            .meta-navigation {
                &__nav {
                    padding: 32px 24px;
                    background-color: $color__primary;

                    @include media-breakpoint-up(lg) {
                        border-radius: $border__radius;
                    }

                    @include media-breakpoint-up(xxl) {
                        padding: 48px;
                    }
                }

                &__list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                &__link {
                    &.button {
                        border: 0;
                    }
                }
            }
        }

        &:not(.meta-navigation--on-banner) {
            .meta-navigation {
                &__list {
                    display: flex;
                    flex-direction: column;
                    gap: 8px 0;

                    @include media-breakpoint-up(lg) {
                        flex-flow: row wrap;
                        gap: 16px 0;
                        justify-content: center;
                        margin-right: $grid-gutter-width * -.5;
                        margin-left: $grid-gutter-width * -.5;
                    }

                    &[data-items='2'],
                    &[data-items='4'] {
                        .meta-navigation {
                            &__item {
                                @include media-breakpoint-up(lg) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }

                &__item {
                    @include media-breakpoint-up(lg) {
                        flex: 0 0 auto;
                        padding-right: $grid-gutter-width * .5;
                        padding-left: $grid-gutter-width * .5;
                        width: 33.333333%;
                    }
                }
            }
        }

        .meta-navigation {
            &__link {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                &.button {
                    display: flex;
                    padding-right: 24px;
                    padding-left: 24px;
                    color: $color__font;
                    background-color: $color__white;
                    border: 1px solid $color__gray;
                    border-radius: $border__radius !important;

                    @include media-breakpoint-up(lg) {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }

                    @include hover-focus-visible {
                        span {
                            &.icon {
                                img {
                                    filter: $filter__font;
                                }
                            }

                            &:not(.icon) {
                                text-decoration: underline;
                                text-decoration-thickness: .08em;

                                + span.icon {
                                    img {
                                        transform: translateX(4px);
                                    }
                                }
                            }
                        }
                    }

                    span {
                        &.icon {
                            flex-shrink: 0;

                            img {
                                filter: $filter__primary;
                                transition: filter .15s linear;
                            }
                        }

                        &:not(.icon) {
                            flex: 1;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                            + span.icon {
                                img {
                                    filter: $filter__font;
                                    transition: transform .2s ease-in-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
